<template>
    <div class="update-module-container">
        <ClassicBreadCrumb :title="$t('module.title')" />
        <div class="module-title">
            <h1>{{ $t("module.title") }}</h1>
        </div>
        <div class="module-search-export">
            <div class="search">
                <BasicSearch
                    :searchPlaceholder="$t('module.table.search')"
                    @search="getModuleList"
                    v-model="search"
                />
            </div>
            <div>
                <BasicButton
                    color="blue"
                    @click="exportModuleList"
                    :disabled="centralesData.length === 0 || disableButton"
                >
                    {{ $t("module.excelExport") }}
                </BasicButton>
            </div>
        </div>
        <div class="module-table">
            <table v-if="!isLoading && centralesData.length > 0">
                <thead>
                    <tr v-if="!isMobileDisplay">
                        <th
                            v-for="(object, index) in filteredHeaders"
                            :key="index"
                            :class="defineClass(object)"
                        >
                            <div>
                                {{ $t(object) }}
                                <Tooltip
                                    :vHtmlText="
                                        $t('module.table.portaconnectInfo')
                                    "
                                    displayMethod="click"
                                    v-if="
                                        object ===
                                        'module.table.portaconnectCompatible'
                                    "
                                >
                                    <i class="fas fa-info-circle"></i>
                                </Tooltip>
                            </div>
                        </th>
                        <th class="replace"></th>
                    </tr>
                    <tr v-else>
                        <th
                            v-for="(object, index) in filteredHeaders"
                            :key="index"
                            :class="defineClass(object)"
                        >
                            <Tooltip
                                v-if="
                                    object ===
                                    'module.table.portaconnectCompatible'
                                "
                                :vHtmlText="$t('module.table.portaconnectInfo')"
                                displayMethod="click"
                            >
                                {{ $t(object) }}
                                <i class="fas fa-info-circle"></i>
                            </Tooltip>
                            <div v-else>
                                {{ $t(object) }}
                            </div>
                        </th>
                        <th class="replace" />
                    </tr>
                </thead>
                <tbody v-if="centralesData.length > 0">
                    <tr v-for="(item, index) in centralesData" :key="index">
                        <td
                            v-for="header in filteredHeaders"
                            :key="header"
                            :class="defineClass(header)"
                        >
                            <div>
                                <i
                                    v-if="
                                        header ===
                                            'module.table.portaconnectCompatible' &&
                                        item[header]
                                    "
                                    class="fas fa-check-circle"
                                ></i>
                                <p>
                                    {{ item[header] }}
                                </p>
                            </div>
                        </td>
                        <td class="replace" @click="goToUpdate(item)">
                            {{ $t("module.table.replace") }}
                            <i class="fas fa-chevron-right"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table v-else-if="!isLoading && centralesData.length === 0">
                <thead>
                    <tr v-if="!isMobileDisplay">
                        <th
                            v-for="(object, index) in headerNoData"
                            :key="index"
                            :class="defineClass(object)"
                        >
                            <div>
                                {{ $t(object) }}
                                <Tooltip
                                    :vHtmlText="
                                        $t('module.table.portaconnectInfo')
                                    "
                                    displayMethod="click"
                                    v-if="
                                        object ===
                                        'module.table.portaconnectCompatible'
                                    "
                                >
                                    <i class="fas fa-info-circle"></i>
                                </Tooltip>
                            </div>
                        </th>
                        <th class="replace"></th>
                    </tr>
                    <tr v-else>
                        <th
                            v-for="(object, index) in headerNoData"
                            :key="index"
                            :class="defineClass(object)"
                        >
                            <Tooltip
                                v-if="
                                    object ===
                                    'module.table.portaconnectCompatible'
                                "
                                :vHtmlText="$t('module.table.portaconnectInfo')"
                                displayMethod="click"
                            >
                                {{ $t(object) }}
                                <i class="fas fa-info-circle"></i>
                            </Tooltip>
                            <div v-else>
                                {{ $t(object) }}
                            </div>
                        </th>
                        <th class="replace" />
                    </tr>
                </thead>
                <tbody class="no-data">
                    <tr v-if="!search">
                        <td colspan="6">
                            <div>
                                {{ $t("module.error.noDataAll") }}
                            </div>
                        </td>
                    </tr>
                    <tr v-else-if="search">
                        <td colspan="6">
                            <div>
                                {{ $t("module.error.noDataSearch") }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-else class="loading">
                <Loader />
            </div>
        </div>
        <div class="pagination">
            <Pagination
                :pagesCount="pagesCount"
                :page="page"
                @new-current-page="setPage"
            />
        </div>
    </div>
</template>

<script>
import ClassicBreadCrumb from "@/components/entities/ClassicBreadCrumb.vue"
import BasicSearch from "@/components/basic/BasicSearch.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import Pagination from "@/components/basic/Pagination.vue"
import Tooltip from "@/components/basic/Tooltip.vue"
import Loader from "@/components/basic/Loader.vue"
import { downloadExcel } from "@/helpers/commonFunctions"

import {
    getCentraleOutdateds,
    exportCentraleOutdateds,
} from "@/services/intratone/centralUnit"

export default {
    name: "UpdateModule",
    components: {
        ClassicBreadCrumb,
        BasicSearch,
        BasicButton,
        Pagination,
        Tooltip,
        Loader,
    },

    data() {
        return {
            //To reactivate portaconnect, update getModuleList() after calling getCentraleOutdateds
            centralesData: [],
            headerNoData: [],
            isLoading: false,
            limit: 10,
            page: 1,
            pagesCount: 0,
            search: null,
            isSearching: false,
            disableButton: false,
        }
    },

    created() {
        if (this.$route.query.search) {
            this.search = this.$route.query.search
        }
    },

    mounted() {
        const query = this.$route.query

        if (query.search) {
            this.getModuleList(query.search)
        } else {
            this.getModuleList()
        }
    },

    computed: {
        headers() {
            return Object.keys(this.centralesData[0] || {})
        },

        filteredHeaders() {
            return this.headers.filter((key) => key !== "id")
        },

        isMobileDisplay() {
            return window.innerWidth <= 1024
        },

        fromRoute() {
            return this.$route.from
        },
    },

    methods: {
        goToUpdate(object) {
            this.$router.push({
                name: "ModuleDetails",
                params: { id: object.id },
            })
        },

        async getModuleList(value) {
            this.isLoading = true
            this.centralesData = []

            if (value) {
                this.search = value
                this.$router.push({ query: { search: value } })
            } else {
                this.search = null
                this.$router.replace({ query: {} })
            }

            const params = {
                limit: this.limit,
                country: "FR",
                page: this.page,
                q: this.search,
            }

            await getCentraleOutdateds(params).then((response) => {
                if (response.data.list.length === 0) {
                    const newArray = [
                        "global.entities.residence",
                        "module.table.centraleName",
                        "module.table.serialModule",
                        "module.table.ref",
                        "module.table.finishDate",
                        // "module.table.portaconnectCompatible",
                    ]

                    this.headerNoData = newArray
                } else {
                    for (let i = 0; i < response.data.list.length; i++) {
                        const newObject = {
                            "global.entities.residence":
                                response.data.list[i].residence_name,
                            "module.table.centraleName":
                                response.data.list[i].centrale_name,
                            "module.table.serialModule":
                                response.data.list[i].numprod,
                            "module.table.ref":
                                response.data.list[i].replacement_reference,
                            "module.table.finishDate": this.getDate(
                                response.data.list[i].finish_date
                            ),
                            // "module.table.portaconnectCompatible":
                            //     response.data.list[i].portaconnect,
                            id: response.data.list[i].centrale_id,
                        }

                        this.centralesData.push(newObject)
                    }
                }

                this.page = response.data._page
                this.pagesCount = response.data._pages

                this.isLoading = false
            })
        },

        getDate(date) {
            if (date) {
                let newDate = new Date(date)

                const month = [
                    "january",
                    "february",
                    "march",
                    "april",
                    "may",
                    "june",
                    "july",
                    "august",
                    "september",
                    "october",
                    "november",
                    "december",
                ]

                for (let i = 0; i < month.length; i++) {
                    if (newDate.getMonth() === i) {
                        let date = this.$t(`date.${month[i]}`, {
                            year: newDate.getFullYear(),
                        })

                        return date.charAt(1).toUpperCase() + date.slice(2)
                    }
                }
            } else {
                return null
            }
        },

        defineClass(object) {
            switch (object) {
                case "module.table.portaconnectCompatible":
                    return "portaconnect"
                case "module.table.ref":
                    return "remplacement"
                case "global.entities.residence":
                    return "residence"
                case "module.table.serialModule":
                    return "serial"
                case "module.table.centraleName":
                    return "centrale"
                default:
                    return ""
            }
        },

        async exportModuleList() {
            this.disableButton = true

            await exportCentraleOutdateds({ q: this.search, country: "FR" })
                .then(async (response) => {
                    downloadExcel("outdated_modules_list", response)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.isLoading = false
                    this.disableButton = false
                })
        },

        setPage(page) {
            this.page = page
            this.getModuleList()
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";
h1,
p {
    margin: 0;
}

h1 {
    font-family: "Avenir Black";
}
.update-module-container {
    padding: 20px 80px 40px 80px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: 100%;
    h1 {
        font-size: 30px;
    }

    @media all and (max-width: 1024px) {
        padding: 2em 1em;
    }

    .module-title {
        display: flex;
        flex-direction: column;
        gap: 1em;

        p {
            font-size: $big;
        }
    }

    .module-search-export {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media all and (max-width: 1024px) {
            flex-direction: column;
            justify-content: start;
            align-items: start;
            gap: 1em;
        }

        .search {
            width: 100%;
            max-width: 500px;
        }
    }

    .module-table {
        width: 100%;
        flex-grow: 1;
        overflow-x: auto;

        table {
            width: 100%;

            tbody {
                &.no-data {
                    tr {
                        td {
                            div {
                                text-align: center !important;
                                gap: 0.5em;
                                font-size: $normal;
                                width: 100%;
                            }

                            font-family: $font_avenir_heavy;
                            color: $pink;
                            border-bottom: 0px;
                        }
                    }
                }
            }

            th {
                padding: 1em 0;
                border-bottom: 1px solid $grey-light;
                min-width: max-content;
                text-wrap: nowrap;
                padding-right: 40px;

                i {
                    color: $orange-neutral;
                    cursor: pointer;
                }

                &.remplacement {
                    width: 150px;
                    text-wrap: wrap;
                }

                &.portaconnect {
                    min-width: 200px;
                    text-wrap: wrap;

                    div {
                        display: flex;
                        align-items: start;
                        gap: 0.5em;
                    }
                }

                &.replace {
                    width: 0px;
                }
            }

            td {
                padding: 1em 0.2em;
                border-bottom: 1px solid $grey-light;
                min-width: max-content;
                padding-right: 35px;
                max-width: 350px;
                height: 100%;

                div {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                    max-height: 3em;
                }

                &.residence {
                    min-width: 150px;
                    max-width: 250px;
                    text-wrap: wrap;

                    div {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                        max-height: 3em;
                    }
                }

                &.portaconnect {
                    div {
                        display: flex;
                        align-items: center;
                        gap: 0.5em;
                    }
                    height: 100%;
                    gap: 1em;
                    align-items: center;
                    width: 200px;
                    i {
                        color: $green-check;
                    }
                }

                &.replace {
                    div {
                        display: flex;
                    }
                    gap: 1em;
                    align-items: center;
                    font-family: $font_avenir_heavy;
                    color: $orange-neutral;
                    text-wrap: nowrap;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .loading {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }

    .module-pagination {
        display: flex;
    }
}
</style>
